import React from 'react'
import Button from '@mui/material/Button';

export default function Btn({onClick, variant, href, className, children, sx}) {
  return (
    <Button 
    href={href} 
    onClick={onClick} 
    className={className} 
    variant={variant}
    // sx={{
    //   '&:hover': {
    //     bgcolor: '#fff !important', // Material-UI uses bgcolor for background-color
    //     color: '#000 !important'
    //   },
    // }}
    sx={sx}
    >
      {children}
    </Button>
  )
}