import React from 'react';
import { createRoot } from 'react-dom/client';
import Layout from '../components/layout/Layout';

import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import App from '../components/App';
import Home from '../src/routes/Home';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);