import React, {useState} from 'react'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const marks = [
  {
    value: 0,
    label: '$0',
    tooltip: "$0 - $5k",
  },
  {
    value: 20,
    label: null,
    tooltip: '$5k - $10k'
  },
  {
    value: 45,
    label: null,
    tooltip: '$10k - $20k'
  },
  {
    value: 71,
    label: null,
    tooltip: '$20k - $50k'
  },
  {
    value: 98,
    label: '$100k+',
    tooltip: '$50k - $100k+'
  }
]

// Function to find the label corresponding to the current value
function valueLabelFormat(value) {
  const mark = marks.find(mark => mark.value === value);
  return mark ? mark.tooltip : value;
}

export default function BudgetSlider({budget, setBudget}) {
  function handleChange(e) {
    let selectValue = valueLabelFormat(e.target.value)
    // console.log(selectValue)
    setBudget(selectValue)
  }

  return (
    <Box sx={{ width: '90%', margin: 'auto' }}>
    <Slider
      aria-label="Custom marks"
      defaultValue={0}
      getAriaValueText={valueLabelFormat}
      step={null}
      valueLabelDisplay="auto"
      marks={marks}
      valueLabelFormat={valueLabelFormat}
      onChange={handleChange}
      sx={{
        '& .MuiSlider-markLabel': {
          color: 'white', // Set the color for the mark labels here
        },
        color: '#35358A', // Changes the color of the track
          '& .MuiSlider-thumb': {
            backgroundColor: '#2E109C', // Color of the thumb
            width: 25,
            height: 25
          },
          '& .MuiSlider-track': {
            backgroundColor: '#3838AB', // Color of the track
            height: 15
          },
          '& .MuiSlider-rail': {
            backgroundColor: 'grey', // Color of the rail
            height: 15
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: '#2E109C', // Background color of the value label
            color: 'white', // Text color of the value label
          }
      }}
    />
  </Box>
  );
}
