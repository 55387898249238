import React from 'react'
import Footer from '../../components/Footer'
import Container from '@mui/material/Container';
import ServiceBox from '../../components/ServiceBox';
import ContactForm from '../../components/ContactForm';
import Btn from '../../components/helpers/Btn';
import Card from '../../components/helpers/Card';
import ServicesSlider from '../../components/ServicesSlider';
import AIAccordion from '../../components/AIAccordion';
import HorizontalScrollbox from '../../components/helpers/HorizontalScrollbox';

export default function Home() {
  return (
    <div class="main-content">
      <Container maxWidth={"xl"} className="home-hero content-wrapper box-shadow-10 d-flex align-items-center justify-content-between mobile-flex-column-reversed">
        <object data="/light.svg" className="light-img"/>
        <div className='d-flex flex-column hero-content col-md-5'>
          <h1 className="text-white mb-3">We Bring Your Ideas To <b>Light.</b></h1>
          <p className='text-white mb-5'>At Nocturnal Development, we specialize in crafting robust web and mobile applications tailored to your unique needs. With a decade of experience in fintech and enterprise-level software, we bring expertise, security, and scalability to every project. Partner with us to turn your vision into a seamless, high-performance solution.</p>
          <Btn href={"#contact-us"} className="btn-custom-primary">Contact</Btn>
        </div>
        <div className="hero-image-wrapper col-md-6 d-flex justify-content-center">
          <object data="/hero_image.svg" className="hero-image"/>
        </div>
      </Container>

      <Container maxWidth={"xl"} className="box-shadow service-box-wrapper">
        <HorizontalScrollbox className={''}> 
          <ServiceBox img={<object className="img-172" data="/web_dev.svg"/>} serviceName={<>Web <br/>Development</>}/>
          <ServiceBox img={<object className="img-172" data="/app_dev.svg"/>} serviceName={<>Application <br/> Development</>}/>
          <ServiceBox img={<object className="img-172" data="/graphic_design.svg"/>} serviceName={<>Graphic <br/> Design</>}/>
          <div className="scroll-box-padding">_</div>
        </HorizontalScrollbox>
      </Container>

      <Container maxWidth={"xl"} className="content-wrapper d-flex box-shadow z-index-5 justify-content-start background-white" sx={{minHeight: "500px"}}>
        <div className="d-flex flex-column content-heading-centered">
          <h2 className="mt-5">COMPREHENSIVE SOLUTIONS</h2>
          <p>At Nocturnal Development, we offer a full spectrum of services to bring your digital projects to life. Our team is equipped to manage every phase of development, ensuring a seamless and efficient process from start to finish.</p>
          <ServicesSlider />
        </div>
      </Container>

      <Container maxWidth={"xl"} className="content-wrapper d-flex box-shadow z-index-5 flex-column align-items-center background-blue-gradient" sx={{minHeight: "500px"}}>
        <div className="d-flex flex-column content-heading-centered">
        <h2 className='text-white mt-3'>UNPARALLELED SECURITY</h2>
          <p className="text-white mb-3">In today’s digital landscape, cybersecurity is more critical than ever. At Nocturnal Development, we prioritize the security of your applications, drawing on our extensive experience in the fintech sector to build robust, hardened solutions.</p>
        </div>
        <HorizontalScrollbox className={"security-boxes mt-5 mb-5"}>
          <ServiceBox className="ms-2" img={<object className="img-172" data="/adv_protection.svg"/>} serviceName={<>ADVANCED <br/> PROTECTION</>}/>
          <ServiceBox img={<object className="img-172" data="/monitoring.svg"/>} serviceName={<>PROACTIVE <br/> MONITORING</>}/>
          <ServiceBox img={<object className="img-172" data="/compliance_standards.svg"/>} serviceName={<>COMPLIANCE & <br/> STANDARDS</>}/>
          <div className="scroll-box-padding">_</div>
        </HorizontalScrollbox>
      </Container>

      <Container maxWidth={"xl"} className="content-wrapper d-flex box-shadow z-index-5 align-items-center background-white justify-content-between py-5" sx={{minHeight: "500px"}}>
        <div className='d-flex flex-column col-md-5'>
          <h2>A TRUSTED PARTNER</h2>
          <p>
            At Nocturnal Development, we understand that navigating the complexities of technology can be daunting,
            especially for non-technical business owners. That’s why we’re committed to being more than just a service provider
            – we aim to be your trusted partner in every step of your digital journey.
          </p>
        </div>
        <div className='d-flex flex-column card-wrapper col-md-6 mobile-padding'>
          <Card textHeader={"Client-Centric Approach"}
                textBody={"We put your needs and goals at the forefront, ensuring that our solutions align with your vision and business objectives."}
                img={<object data="/client-centric.svg" className="card-background-image"/>}
          />
          <Card textHeader={"Expert Guidance"}
                textBody={"Our experienced team is here to offer clear, understandable advice and support, making the technical aspects of your project manageable and stress-free."}
                img={<object data="/expert-guidance.svg" className="card-background-image"/>}
          />
          <Card textHeader={"Long-Term Partnership"}
                textBody={"We believe in building lasting relationships with our clients. From initial consultation to ongoing support, we are dedicated to your success and ready to assist with any technical needs that arise."}
                img={<object data="/partnership.svg" className="card-background-image"/>}
          />
          <Card textHeader={"Reliable Support"}
                textBody={"Whether it's troubleshooting an issue, updating features, or planning for future growth, you can count on us to be there, providing dependable and responsive service."}
                img={<object data="/reliable-support.svg" className="card-background-image"/>}
          />
        </div>
      </Container>

      <Container maxWidth={"xl"} className="content-wrapper box-shadow d-flex z-index-5 content-wrapper-reversed align-items-center justify-content-between background-blue-gradient" sx={{minHeight: "500px"}}>
        <object data="/ai.svg" className="ai-img"/>
        <div className='d-flex flex-column col-md-5'>
          <h2 className='text-white'>LEVERAGING AI FOR YOUR BUSINESS</h2>
          <p className='text-white'>At Nocturnal Development, we harness the power of artificial intelligence to deliver exceptional value to our clients. By integrating AI into our development processes, we not only streamline project timelines but also provide cost-effective solutions that drive innovation and growth.</p>
        </div>
        <div className="col-md-6">
          <AIAccordion/>
        </div>
      </Container>

      <Container id="contact-us" maxWidth={"xl"} className="d-flex box-shadow z-index-5 flex-column pt-5 pb-5 align-items-center background-white" sx={{minHeight: "500px"}}>
        <ContactForm />
      </Container>
      <Footer />
    </div>
  )
}