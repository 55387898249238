import * as React from 'react'
import { Box } from '@mui/material'

export default function ServiceBox({serviceName, img}) {
  const [isVisible, setIsVisible] = React.useState(false);
  const textRef = React.useRef(null);

  React.useEffect(() => {
    // Ensure IntersectionObserver is supported
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Disconnect after visible to stop observing
          }
        });
      }, { threshold: 0.2 });

      if (textRef.current) {
        observer.observe(textRef.current);
      }

      return () => {
        if (observer) {
          observer.disconnect();
        }
      };
    }
  }, []);

  return (
    <Box sx={{opacity: isVisible ? 1 : 0, transition: "opacity 0.4s ease-out"}} ref={textRef} className="service-box d-flex flex-column justify-content-between align-items-center">
      {img}
      <span>{serviceName}</span>
    </Box>
  )
}