import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function TextFieldInput({value, setValue, numRows, label, variant, className, multiline, required, formClassName }) {
  function handleChange(e) {
    // console.log(value)
    setValue(e.target.value)
  }
  return (
    <Box
    component="form"
    noValidate
    autoComplete="off"
    className={formClassName}
    >
      <TextField onChange={handleChange} value={value} InputProps={{className: "text-white"}} InputLabelProps={{style: {color: '#fff'}}} required={required} className={className} multiline={multiline} rows={numRows} label={label} variant={variant}/>
    </Box>
  )
}