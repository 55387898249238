export function submitContactForm(formFields, onSuccess, onError) {
  setTimeout(() => {
    fetch("/contact_forms", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contact_form: formFields,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          // Checks if response status code is outside of 2xx
          return res.json().then((err) => {
            // Process the JSON error object
            throw err; // Throws error with JSON object that the catch block can receive
          });
        }
        return res.json();
      })
      .then((data) => onSuccess(data))
      .catch((err) => onError(err));
  }, 1500);
}
