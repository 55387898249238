import * as React from 'react'
import {Backdrop} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function SimpleBackdrop({open, setOpen}) {
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: 999 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}