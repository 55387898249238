import React from 'react'
import Box from '@mui/material/Box';
import {Stepper} from '@mui/material';
import {Step} from '@mui/material';
import {StepButton} from '@mui/material';
import StepConnector, {stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

const steps = ['UI/UX Design', 'Development', 'Deployment', 'Maintenance and Support'];

const StepperConnector = styled(StepConnector) (({theme, ownerState}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  '.previous & .MuiStepConnector-line': {
    backgroundImage:  'linear-gradient(90deg,rgb(247,4,254) 0%,rgb(36,188,227) 100%)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:  'linear-gradient(90deg,rgb(247,4,254) 0%,rgb(36,188,227) 100%)',

    },
  },
  [`&.${stepConnectorClasses.previous}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 15,
    border: 0,
    backgroundColor: "#0A0A14",
    borderRadius: 1,
    top: 15,
    zIndex: 1
  },
}));

export default function ServicesSlider() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
      
      
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const stepData = {
    0: {header: "UI/UX Design", text: "We create intuitive and engaging interfaces that enhance user experience and drive engagement.", img: <img className="img-192" src="/web_dev.svg"/>},
    1: {header: "Development", text: "Our expertise in Ruby on Rails and other modern technologies ensures robust, scalable, and secure applications tailored to your specific needs.", img: <img className="img-192" src="/app_dev.svg"/>},
    2: {header: "Deployment", text: "We handle the complexities of deployment, ensuring your application runs smoothly and efficiently in its intended environment.", img: <img className="img-192" src="/deployment.svg"/>},
    3: {header: "Maintenance and Support", text: "Post-deployment, we provide ongoing support and maintenance to ensure your application remains up-to-date, secure, and fully functional.", img: <img className="img-192" src="/support.svg"/>}
  }
  
  function stepperCircleActive(index) {
    if (index == activeStep) {
      return 'active'
    } else {
      return ''
    }
  }

  return (
    <Box className="mt-5 mb-5" sx={{ width: '100%' }}>
      <div className="mb-4">
        <React.Fragment>
          <Box className="stepper-content mobile-padding border-accent">
            <span>{stepData[activeStep].img}</span>
            <h3 className="mb-3 mt-3">{stepData[activeStep].header}</h3>
            <p>{stepData[activeStep].text}</p>
            <div className='mobile-stepper'>
              <div onClick={handleStep(0)} className={`${stepperCircleActive(0)} mobile-stepper-circle`}></div>
              <div onClick={handleStep(1)} className={`${stepperCircleActive(1)} mobile-stepper-circle`}></div>
              <div onClick={handleStep(2)} className={`${stepperCircleActive(2)} mobile-stepper-circle`}></div>
              <div onClick={handleStep(3)} className={`${stepperCircleActive(3)} mobile-stepper-circle`}></div>
          </div>
          </Box> 
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
          </Box>
        </React.Fragment>
      </div>
      <Stepper className="desktop-stepper" connector={<StepperConnector ownerState={{activeStep}} />} alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]} className={index < activeStep ? 'previous' : ''}>
            <StepButton icon={<div className={`stepper-circle ${activeStep >= index ? "circle-active" : ""}`}></div>} color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}