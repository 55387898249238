import React from 'react'
import TextLogo from './TextLogo'

export default function Footer() {
  return (
    <footer className="footer d-flex flex-column align-items-center pb-2 pt-4">
      <TextLogo />
      <p className="copyright-text mt-4">2024 Nocturnal Development, LLC</p>
    </footer>
  )
}