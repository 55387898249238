import React from 'react'
import Box from '@mui/material/Box';

export default function Card({textBody, textHeader, img}) {
  return (
    <Box component="section" className="card border-accent" sx={{ p: 2 }}>
      {img}
      <h4>{textHeader}</h4>
      <p>{textBody}</p>
    </Box>
  )
}