import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import TextLogo from '../TextLogo';
import Btn from './Btn';

const pages = [];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isActive, setIsActive] = React.useState(false);
  const offsetThreshold = 800; // Change this to your specific pixel offset
  const [animated, setAnimated] = React.useState(false)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function setActive(active) {
    if (animated == false && active == true) {
      setAnimated(active)
    }
    setIsActive(active)
  }

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setActive(currentScrollPos > offsetThreshold);
    };

    // Attach the scroll event handler
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('scroll', handleScroll);
  }, [offsetThreshold]);

  return (
    <AppBar className={`${isActive ? 'navbar-active' : 'navbar-custom'} ${animated ? 'navbar-custom' : 'navbar-anim'}`}>
      <Container maxWidth="xl">
        <Toolbar className="d-flex justify-content-between" disableGutters>
          <TextLogo height="68" width="183"/>
          {/* This is for mobile */}
            <Btn sx={{ display: { xs: 'block', md: 'none', lg: 'none', xl: 'none' } }} href={"#contact-us"} className="btn-custom-primary contact-btn">Contact</Btn>
          {/* Desktop Version */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, width: { md: "100%" }, justifyContent: "flex-end", alignItems: "center" }} >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
            <Btn href={"#contact-us"} className="btn-custom-primary">Contact</Btn>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
