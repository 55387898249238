import React from 'react'

export default function HorizontalScrollbox({children, className}) {
  return (
    <div className={`${className} horizontal-scroll-box`}>
      <div className="scroll-content">
        {children}
      </div>
    </div>
  )
}