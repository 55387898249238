import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {Select} from '@mui/material';
import Box from '@mui/material/Box'

export default function BasicSelect({value, setValue}) {
  const handleChange = (event) => {
    // console.log(value)
    setValue(event.target.value);
  };

  return (
    <Box
    component="form"
    noValidate
    autoComplete="off"
    >
      <FormControl fullWidth>
        <InputLabel className="text-white" id="demo-simple-select-label">Preferred Method of Contact</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Contact Method"
          onChange={handleChange}
          className="text-field-input m-none col-md-12"
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: "rgba(17, 11, 39, 1)", // Background color of the dropdown
                boxShadow: 'none', // Removes default shadow
                border: '1px solid #2E109C', // Custom border
              }
            }
          }}
        >
          <MenuItem className="text-white" value="email">Email</MenuItem>
          <MenuItem className="text-white" value="phone">Phone</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}