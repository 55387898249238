import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} className="border-accent" />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
  expandIcon={<ArrowForwardIosSharpIcon sx={{ color: 'white', marginRight: '5px' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function AIAccordion() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="accordion-wrapper mobile-padding">
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="accordion-header">
          <h4>COST SAVINGS</h4>
        </AccordionSummary>
        <AccordionDetails className="accordion-content">
          <p>
            Our AI-driven methodologies automate repetitive tasks and optimize resource allocation, significantly reducing the overall project cost. This allows us to offer high-quality solutions within your budget, maximizing your return on investment.
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" className="accordion-header">
          <h4>FASTER PROJECT COMPLETION</h4>
        </AccordionSummary>
        <AccordionDetails className="accordion-content">
          <p>
            With AI-enhanced tools and processes, we accelerate development cycles, ensuring your projects are completed on time and with exceptional quality. From automated code generation to intelligent testing, our AI capabilities enhance every stage of the development lifecycle.
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" className="accordion-header">
          <h4>AI INTEGRATION FOR YOUR BUSINESS</h4>
        </AccordionSummary>
        <AccordionDetails className="accordion-content">
          <p>
            Beyond our internal use of AI, we are committed to helping you explore and implement AI solutions tailored to your unique business needs. Whether it’s improving customer experiences through chatbots, leveraging data analytics for informed decision-making, or automating operational processes, we provide comprehensive support to integrate AI seamlessly into your operations.
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" className="accordion-header">
          <h4>TRANSFORMATIVE RESULTS</h4>
        </AccordionSummary>
        <AccordionDetails className="accordion-content">
          <p>
            Partnering with Nocturnal Development means gaining access to cutting-edge AI technologies that transform your business. Our team of experts will work closely with you to identify opportunities, develop customized AI strategies, and deploy solutions that drive efficiency, innovation, and competitive advantage.
          </p>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}