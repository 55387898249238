import * as React from 'react';
import {Alert} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export default function SimpleAlert({errors, text, severity, className, showAlert, setShowAlert}) {

  function renderErrors() {
    if (errors.length > 0) {
      return errors.map((error) => {
        return <li>{error}</li>
      })
    } 
  }

  return (
    <Alert className={className} onClose={() => setShowAlert(false)} icon={<CheckIcon fontSize="inherit" />} severity={severity} sx={{display: showAlert ? "flex" : "none"}}>
      {text}
      {renderErrors()}
    </Alert>
  );
}