import React, {useState} from 'react'
import TextFieldInput from './TextFieldInput'
import BudgetSlider from './BudgetSlider'
import Btn from './helpers/Btn'
import BasicSelect from './BasicSelect'
import { popperOffsets } from '@popperjs/core'
import { submitContactForm } from './helpers/api'
import SimpleBackdrop from './helpers/SimpleBackdrop'
import SimpleAlert from './helpers/SimpleAlert'

export default function ContactForm() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [preferredContact, setPreferredContact] = useState('email')
  const [budget, setBudget] = useState('0')
  const [moreInfo, setMoreInfo] = useState('')
  const [open, setOpen] = React.useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success')
  const [alertText, setAlertText] = useState('')
  const [errors, setErrors] = useState([])

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  function handleSubmit() {
    let formFields = {
      first_name: firstName, 
      last_name: lastName, 
      email: email, 
      phone: phone,
      preferred_contact_method: preferredContact,
      estimated_budget: budget,
      more_info: moreInfo
    }

    handleOpen()
    submitContactForm(formFields, data => onSuccess(data), err => onError(err))
  }

  function onSuccess(data) {
    handleClose()
    setShowAlert(true)
    setAlertSeverity('success')
    setAlertText("Your form has been successfully submitted! We'll be in touch shortly.")
  }

  function onError(err) {
    handleClose()
    setShowAlert(true)
    setAlertSeverity('error')
    if (err.errors.length > 0) {
      setErrors(err.errors)
      setAlertText(err.message)
    } else {
      setAlertText(err.message)
    }
  }

  return (
    <div className="form-background d-flex flex-column align-items-center">
      <h1 className="text-white mb-4">Contact Us</h1>
      <div className="form-elements d-flex flex-column col-md-12">
      <SimpleAlert errors={errors} severity={alertSeverity} text={alertText} className="mb-4" showAlert={showAlert} setShowAlert={setShowAlert} />
        <div className='d-flex col-md-12 mobile-flex-column'>
          <TextFieldInput value={firstName} setValue={setFirstName} formClassName="flex-fill" className="text-field-input flex-fill form-elements" required={true} label="First Name"/>
          <TextFieldInput value={lastName} setValue={setLastName} formClassName="flex-fill" className="text-field-input flex-fill form-elements" label="Last Name"/>
        </div>
        <TextFieldInput value={email} setValue={setEmail} className="text-field-input form-elements mb-4" required={true} label="Email"/>
        <TextFieldInput value={phone} setValue={setPhone} className="text-field-input form-elements mb-4" label="Phone Number"/>
        <BasicSelect value={preferredContact} setValue={setPreferredContact} />
        <div className='d-flex flex-column mt-4 mb-4 align-items-center'>
          <span className="text-white">Estimated Budget</span>
          <BudgetSlider budget={budget} setBudget={setBudget} className="form-elements"/>
        </div>
        <TextFieldInput value={moreInfo} setValue={setMoreInfo} className="text-field-input form-elements" numRows={5} multiline={true} label="Tell Us More" id="filled-multiline-static"/>
      </div>
      <Btn onClick={handleSubmit} className="mt-4 mb-5 contact-btn">Submit</Btn>
      <SimpleBackdrop open={open} setOpen={setOpen}/>
    </div>
  )
}